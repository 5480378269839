import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'ajaxified',
    // Country/City/Ports Population Targets
    'pol',
    // Association Options
    'vessel', 'hsCode',
    // Person Relations
    'shipperName', 'shipperPhone', 'shipperAddress',
    'consigneeName', 'consigneePhone', 'consigneeAddress',
    'notifyName', 'notifyPhone', 'notifyAddress',
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    if (this.hasAjaxifiedTarget) {
      let request_url = `/${this.base_country}/api/`
      this.ajaxifiedTargets.forEach(element => {
        if (element === this.vesselTarget) {
          let url = `${request_url}vessels?`
          this.makeAjaxifiedSelect(element, url, 'vessel')
        } else if (element === this.hsCodeTarget) {
          let url = `${request_url}hs_codes?`
          this.makeAjaxifiedSelect(element, url, 'hsCode')
        }
      })
    }
    const addGoodBtn = document.querySelector('.add_fields[data-assoctype="goods"]')
    setTimeout(() => {
      if (addGoodBtn) addGoodBtn.click();
    }, 1000)
  }

  removeGoodRow(event) {
    event.currentTarget.closest('.assoc-good-row').style.display = 'none'
    event.currentTarget.closest('.assoc-good-row').querySelector('[type="hidden"]').value = "true"
    if (event.currentTarget.dataset.relatedbutton)
      document.querySelector(`.add_fields.${event.currentTarget.dataset.relatedbutton}`)
        .classList.remove('disabled', 'pointer-events-none', 'cursor-not-allowed', 'opacity-50')
    return event.preventDefault()
  }

  populate(event) {
    const selectBox = event.target,
      target = this[selectBox.dataset.populatetarget]

    target.slim.enable()
    target.setAttribute('data-countryid', selectBox.value)

    if (target.dataset.afterpopulatecityid) {
      target.slim.setData([{ text: target.dataset.afterpopulatecityname, value: target.dataset.afterpopulatecityid }])
      target.slim.setSelected(target.dataset.afterpopulatecityid)
      target.removeAttribute('data-afterpopulatecityname')
      target.removeAttribute('data-afterpopulatecityid')
    } else if (target.dataset.entitytype !== 'consignees') {
      target.slim.setData([{ text: '', placeholder: true }])
      target.slim.setSelected(0)
    }
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}PhoneTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      return
    }

    const { data: person } = response
    this[`${entityName}PhoneTarget`].value = person.phone
    this[`${entityName}AddressTarget`].value = person.address
  }
}
