import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["vessel_country", "vessel_imo_code", "required_imo_code", "required_mmsi_code"]

    toggleIMOCode() {
        const selectedCountry = this.vessel_countryTarget.value;
        if (selectedCountry === 'Djibouti' || selectedCountry === 'Somalia') {
            this.vessel_imo_codeTarget.classList.remove('hidden');
        } else if (selectedCountry === 'Cameroon') {
            this.required_imo_codeTarget.classList.remove('hidden');
            this.required_mmsi_codeTarget.classList.remove('hidden');
        } else {
            this.vessel_imo_codeTarget.classList.add('hidden');
        }
    }
}
