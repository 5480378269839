import CountryBaseController from "@/controllers/country_base_controller";
import SlimSelect from 'slim-select'

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'ajaxified', 'shipmentType', 'numberOfVehicles', 'vehicleBox', 'shipmentBox', 'numberOfVehiclesInput',
    // Country/Ports Population Targets
    'pol',
    // Association Options
    'vessel', 'city', 'line',
    // Person Relations
    'searchShipper', 'searchConsignee',
    'companyCity', 'companyCountry',
    'shipperName', 'shipperEmail', 'shipperPhone', 'shipperAddress', 'shipperCity', 'shipperCountry',
    'consigneeName', 'consigneeEmail', 'consigneePhone', 'consigneeAddress', 'consigneeCity', 'consigneeTaxpayerNumber',
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    if (this.hasAjaxifiedTarget) {
      let request_url = `/${this.base_country}/api/`
      this.ajaxifiedTargets.forEach(element => {
        if (this.cityTargets.includes(element)) {
          let url = `${request_url}cities?`
          this.makeAjaxifiedSelect(element, url, 'city')
        }
      })
    }

    this.showHideNumOfVehicles();

    const vehicleRows = this.vehicleBoxTarget.querySelectorAll('.assoc-row')
    const roroRows = this.shipmentBoxTarget.querySelectorAll('.assoc-row.roro')
    const addVehicleButton = document.querySelector('.add_fields[data-assoctype="vehicles"]')
    if (vehicleRows.length > 0) {
      this.vehicleBoxTarget.classList.remove('hidden')
      this.vehicleBoxTarget.classList.add('flex')
      addVehicleButton.classList.remove('hidden')

      vehicleRows.forEach(row => {
        const modelSelect = row.querySelector('select[name*="[model]"]');
        if (modelSelect) {
          this.makeDynamicSelectModel(modelSelect);
        }
      });
    }

    if (roroRows.length > 0) {
      roroRows.forEach(row => {
        const modelSelect = row.querySelector('select[name*="[model]"]');
        if (modelSelect) {
          this.makeDynamicSelectModel(modelSelect);
        }
      });
    }

    const addGoodBtn = document.querySelector('.add_fields[data-assoctype="goods"]')
    const route = window.location.href.split('/').pop()
    setTimeout(() => {
      if (addGoodBtn && route === "new") addGoodBtn.click();
    }, 1000)

    this.dataMaps = {
      line: new Map(),
      vessel: new Map(),
      shipper: new Map(),
      consignee: new Map()
    };

    if (this.hasLineTarget) this.makeDynamicSelectCameroon(this.lineTarget, "line")
    if (this.hasVesselTarget) this.makeDynamicSelectCameroon(this.vesselTarget, "vessel")
    if (this.hasSearchShipperTarget) this.makeDynamicShipperSelect(this.searchShipperTarget, "shipper")
    if (this.hasSearchConsigneeTarget) this.makeDynamicShipperSelect(this.searchConsigneeTarget, "consignee")
  }

  makeDynamicSelectModel(element) {
    const endpoint = "/api/cameroon/models/search";
    console.log(`Creating dynamic select for model:`, element);

    const selectedValue = element.dataset.selectedValue || element.value;
    if (selectedValue) {
      const option = document.createElement('option');
      option.value = selectedValue;
      option.text = selectedValue;
      option.selected = true;
      element.appendChild(option);
    }

    const modelSlimSelect = new SlimSelect({
      select: element,
      placeholder: element.dataset.placeholder || '',
      searchingText: 'Searching...',
      allowDeselect: true,
      events: {
        search: async (search) => {
          if (search.length < 3) {
            console.log(`Search term too short:`, search);
            return [];
          }
          console.log(`Searching for model:`, search);
          const reqUrl = `${endpoint}?name=${encodeURIComponent(search)}`;
          console.log(`Fetching from backend (model):`, reqUrl);

          try {
            const response = await fetch(reqUrl, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const json = await response.json();

            const options = json.map(item => {
              return {
                text: `${item.name},${item.brand?.name}`,
                value: `${item.name},${item.brand?.name}`
              };
            });

            return options;
          } catch (error) {
            console.error(`Error fetching model:`, error);
            return [];
          }
        }
      }
    });

    console.log(`SlimSelect instance for model:`, modelSlimSelect);
    return modelSlimSelect;
  }

  makeDynamicShipperSelect(element, type) {
    const endpoint = type === "shipper" ? "/api/cameroon/shippers/search" : "/api/cameroon/consignees/search";

    const debounceSearch = this.debouncePromise(async (search, currentData) => {
      if (search.length < 3) {
        return [];
      }

      const reqUrl = `${endpoint}?name=${encodeURIComponent(search)}`;
      console.log(`Fetching from backend (${type}):`, reqUrl);

      try {
        const response = await fetch(reqUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();

        const options = json.map(item => {
          this.dataMaps[type].set(item.id.toString(), {
            name: item.name,
            email: item.email,
            phone: item.phone,
            address: item.address,
            city: item.town,
            country: item.country,
            taxpayer_number: item.taxpayer_number || "",
          });

          return {
            text: item.name,
            value: item.id.toString()
          };
        });

        return options;
      } catch (error) {
        console.error(`Error fetching ${type}:`, error);
        return [];
      }
    });

    const selectInstance = new SlimSelect({
      select: element,
      placeholder: element.dataset.placeholder || '',
      searchingText: 'Searching...',
      allowDeselect: true,
      events: {
        search: debounceSearch
      }
    });

    element.removeEventListener("change", this.handleShipperSelectionChange);
    element.addEventListener("change", this.handleShipperSelectionChange.bind(this, type));

    return selectInstance;
  }

  handleShipperSelectionChange(type, event) {
    const element = event.target;
    const selectedOption = element.options[element.selectedIndex];

    if (selectedOption) {
      const selectedData = this.dataMaps[type].get(selectedOption.value) || {};
      console.log(`Selected ${type}:`, selectedData);
      if (type === "shipper") {
        this.shipperNameTarget.value = selectedData.name || "";
        this.shipperEmailTarget.value = selectedData.email || "";
        this.shipperPhoneTarget.value = selectedData.phone || "";
        this.shipperAddressTarget.value = selectedData.address || "";
      } else if (type === "consignee") {
        this.consigneeNameTarget.value = selectedData.name || "";
        this.consigneeEmailTarget.value = selectedData.email || "";
        this.consigneePhoneTarget.value = selectedData.phone || "";
        this.consigneeAddressTarget.value = selectedData.address || "";
        this.consigneeTaxpayerNumberTarget.value = selectedData.taxpayer_number || "";
      }
    }
  }

  makeDynamicSelectCameroon(element, type) {
    const endpoint = type === "line" ? "/api/cameroon/lines/search" : "/api/cameroon/vessels/search";

    const debounceSearch = this.debouncePromise(async (search, currentData) => {
      if (search.length < 3) {
        return [];
      }

      const reqUrl = `${endpoint}?name=${encodeURIComponent(search)}`;
      console.log(`Fetching from backend (${type}):`, reqUrl);

      try {
        const response = await fetch(reqUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();

        const options = json.map(item => {
          if (type === "vessel") {
            this.dataMaps[type].set(item.id.toString(), {
              name: item.name,
              imo_code: item.imo,
              mmsi_code: item.mmsi
            });
          } else if (type === "line") {
            this.dataMaps[type].set(item.id.toString(), {
              name: item.name
            });
          }

          return {
            text: item.name,
            value: item.id.toString()
          };
        });

        return options;
      } catch (error) {
        console.error(`Error fetching ${type}:`, error);
        return [];
      }
    }, 300);

    const selectInstance = new SlimSelect({
      select: element,
      placeholder: element.dataset.placeholder || '',
      searchingText: 'Searching...',
      allowDeselect: true,
      events: {
        search: debounceSearch
      }
    });

    element.removeEventListener("change", this.handleSelectionChange);
    element.addEventListener("change", this.handleSelectionChange.bind(this, type));

    return selectInstance;
  }

  handleSelectionChange(type, event) {
    const element = event.target;
    const selectedOption = element.options[element.selectedIndex];

    if (selectedOption) {
      const form = element.closest("form");

      const selectedData = this.dataMaps[type].get(selectedOption.value) || {};
      const name = selectedData.name || "";
      const imo_code = selectedData.imo_code || "";
      const mmsi_code = selectedData.mmsi_code || "";

      const idFieldName = type === "line" ? "entry[line_id]" : "entry[vessel_id]";
      const nameFieldName = type === "line" ? "entry[line_attributes][name]" : "entry[vessel_attributes][name]";
      const imoFieldName = "entry[vessel_attributes][imo_code]";
      const mmsiFieldName = "entry[vessel_attributes][mmsi_code]";

      console.log(`Selected ${type}:`, {
        id: selectedOption.value,
        name: name,
        imo_code: imo_code,
        mmsi_code: mmsi_code
      });

      this.addOrUpdateHiddenField(form, idFieldName, selectedOption.value);
      this.addOrUpdateHiddenField(form, nameFieldName, name);
      if (type === "vessel") {
        this.addOrUpdateHiddenField(form, imoFieldName, imo_code);
        this.addOrUpdateHiddenField(form, mmsiFieldName, mmsi_code);
      }
    }
  }

  addOrUpdateHiddenField(form, name, value) {
    let input = form.querySelector(`input[name="${name}"]`);
    if (!input) {
      input = document.createElement("input");
      input.type = "hidden";
      input.name = name;
      form.appendChild(input);
    }
    input.value = value;
  }

  debouncePromise(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      return new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          func(...args).then(resolve).catch(reject);
        }, wait);
      });
    };
  }


  removeGoodRow(event) {
    event.currentTarget.closest('.assoc-good-row').style.display = 'none'
    event.currentTarget.closest('.assoc-good-row').querySelector('[type="hidden"]').value = "true"
    if (event.currentTarget.dataset.relatedbutton)
      document.querySelector(`.add_fields.${event.currentTarget.dataset.relatedbutton}`)
        .classList.remove('disabled', 'pointer-events-none', 'cursor-not-allowed', 'opacity-50')
    return event.preventDefault()
  }

  populate(event) {
    const selectBox = event.target,
      target = this[selectBox.dataset.populatetarget]

    target.slim.enable()
    target.setAttribute('data-countryid', selectBox.value)

    if (target.dataset.afterpopulatecityid) {
      target.slim.setData([{ text: target.dataset.afterpopulatecityname, value: target.dataset.afterpopulatecityid }])
      target.slim.setSelected(target.dataset.afterpopulatecityid)
      target.removeAttribute('data-afterpopulatecityname')
      target.removeAttribute('data-afterpopulatecityid')
    } else if (target.dataset.entitytype !== 'consignees') {
      target.slim.setData([{ text: '', placeholder: true }])
      target.slim.setSelected(0)
    }
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}EmailTarget`].value = ''
      this[`${entityName}PhoneTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      if (entityType !== 'consignees') {
        this[`${entityName}CountryTarget`].slim.setSelected(0)
        this[`${entityName}CityTarget`].slim.setData([{ text: '', placeholder: true }])
      }
      this[`${entityName}CityTarget`].slim.setSelected(0)
      return
    }

    const { data: person } = response
    this[`${entityName}EmailTarget`].value = person.email
    this[`${entityName}PhoneTarget`].value = person.phone
    this[`${entityName}AddressTarget`].value = person.address
    if (entityType === 'consignees') { // consignee has only city
      this[`${entityName}CityTarget`].slim.setSelected(person['city-id'])
    } else { // other people have both country and city
      this[`${entityName}CityTarget`].setAttribute("data-afterpopulatecityname", person.city)
      this[`${entityName}CityTarget`].setAttribute("data-afterpopulatecityid", person['city-id'])
      this[`${entityName}CountryTarget`].slim.setSelected(person['country-id'])
    }
  }

  showHideNumOfVehicles() {
    if (this.shipmentTypeTarget.value === 'Container' || this.shipmentTypeTarget.value === 'RoRo') {
      this.numberOfVehiclesTarget.classList.remove('hidden')
    } else {
      this.numberOfVehiclesTarget.classList.add('hidden')
    }
  }

  makeDynamicLastRowsModel() {
    const shipmentType = this.shipmentTypeTarget.value;
    console.log("New row added for shipment type:", shipmentType);

    setTimeout(() => {
      if (shipmentType === 'Container') {
        const vehicleRows = this.vehicleBoxTarget.querySelectorAll('.assoc-row');
        console.log("Vehicle rows found:", vehicleRows.length);
        vehicleRows.forEach(row => {
          const modelSelect = row.querySelector('select[name*="[model]"]');
          if (modelSelect) {
            if (modelSelect.slim) {
              modelSelect.slim.destroy();
            }
            this.makeDynamicSelectModel(modelSelect);
          } else {
            console.error('No model select element found in the new row!');
          }
        }
        );
      } else {
        const roroRows = this.shipmentBoxTarget.querySelectorAll('.assoc-row.roro');
        console.log("RoRo rows found:", roroRows.length);
        roroRows.forEach(row => {
          const modelSelect = row.querySelector('select[name*="[model]"]');
          if (modelSelect) {
            if (modelSelect.slim) {
              modelSelect.slim.destroy();
            }
            this.makeDynamicSelectModel(modelSelect);
          } else {
            console.error('No model select element found in the new row!');
          }
        });
      }
    }, 500);
  }

  showHideVehicleBox() {
    const value = parseInt(this.numberOfVehiclesInputTarget.value, 10);
    const addVehicleButton = document.querySelector('.add_fields[data-assoctype="vehicles"]');
    const shipmentType = this.shipmentTypeTarget.value;

    if (value === 0 || isNaN(value)) {
      this.vehicleBoxTarget.classList.add("hidden");
    } else {
      if (shipmentType === 'Container') {
        this.vehicleBoxTarget.classList.remove("hidden");
        this.vehicleBoxTarget.classList.add("flex");
        addVehicleButton.classList.remove('hidden');
        this.updateVehicleRows(value, "Container");
      } else {
        const existingRows = this.vehicleBoxTarget.querySelectorAll('.assoc-row');
        existingRows.forEach(row => row.remove());
        this.vehicleBoxTarget.classList.add("hidden");
        this.updateVehicleRows(value, "RoRo");
      }
    }
  }

  updateVehicleRows(count, shipmentType) {
    const addVehicleButton = document.querySelector('.add_fields[data-assoctype="vehicles"]');
    const addRoroButton = document.querySelector('.add_fields[data-assoctype="roros"]');

    const addNewButton = shipmentType === 'Container' ? addVehicleButton : addRoroButton;

    const existingRows = shipmentType === 'Container' ? this.vehicleBoxTarget.querySelectorAll('.assoc-row') : this.shipmentBoxTarget.querySelectorAll('.assoc-row.roro');

    const observeBox = shipmentType === 'Container' ? this.vehicleBoxTarget : this.shipmentBoxTarget;

    if (existingRows.length >= count) {
      existingRows.forEach((row, index) => {
        if (index >= count) {
          row.remove();
        }
      });
    }

    const rowsToAdd = count - existingRows.length;

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const newRows = Array.from(mutation.addedNodes).filter(node =>
            node.classList && node.classList.contains('assoc-row') || node.classList.contains('roro')
          );

          newRows.forEach(newRow => {
            const modelSelect = newRow.querySelector('select[name*="[model]"]');

            if (modelSelect) {
              console.log(modelSelect);
              if (modelSelect.slim) {
                modelSelect.slim.destroy();
              }
              this.makeDynamicSelectModel(modelSelect);
            } else {
              console.error('No model select element found in the new row!');
            }
          });
        }
      }
    });

    observer.observe(observeBox, { childList: true, subtree: false });

    for (let i = 0; i < rowsToAdd; i++) {
      addNewButton.click();
    }

    setTimeout(() => observer.disconnect(), 1000);
  }
  
}
